<script lang="ts" setup>
import { useAuthStore } from '~/stores/auth'
import Dropdown from '~/components/Common/Dropdown.vue'

const authStore = useAuthStore()
const { logout, me } = useAuth()
const route = useRoute()

const menuOpened = ref(false)
const showDropdown = ref(false)

const user = computed(() => authStore.user)
const isAdmin = computed(() => authStore.isAdmin)
const isJudge = computed(() => authStore.isJudge)
const isSponsor = computed(() => authStore.isSponsor)

const showCartButton = computed(() => {
  return isSponsor.value && !user.value?.tradeProfile?.Contribution?.length && !user.value?.carts?.[0]?.Purchase
})

const placeName = computed<string>(() => {
  if (route.meta.spaceName === 'dashboard')
    return 'Dashboard'

  if (route.meta.spaceName === 'admin')
    return 'Admin'

  if (route.meta.spaceName === 'judge')
    return 'Judge'

  return ''
})

const style = {
  rightButton: (active = false) => [
    'w-9 h-9 rounded-xl p-1.5 border text-t-text flex items-center transition-colors justify-center relative',
    'lg:w-11 lg:h-11 lg:p-2.5 lg:rounded-2xl',
    {
      'border-t-border hover:border-t-text': !active,
      'border-t-text': active,
    },
  ],
  loginButton: (active = false) => [
    'w-14 h-9 rounded-xl p-1.5 border text-t-text flex items-center transition-colors justify-center relative',
    'lg:w-14 lg:h-11 lg:p-4 lg:rounded-2xl',
    {
      'border-t-border hover:border-t-text': !active,
      'border-t-text': active,
    },
  ],
  rightButtonActive: 'theme-green bg-t-bg !border-t-bg',
  dropdownMenu: 'w-52 theme-neutral-light dark:theme-green-light bg-t-bg mt-2 rounded-2xl shadow-lg p-2 text-t-text-light',
  dropdownItem: 'not-first:mt-2',
  dropdownLink: 'px-4 py-2 block w-full rounded-xl text-left heading text-lg hover:border-t-text hover:text-t-text text-t-text-light border border-t-border transition-colors',
  dropdownLinkActive: 'theme-green-light dark:theme-neutral-light bg-t-bg !border-t-bg !text-t-text',
}

const menuItems = computed(() => {
  const items = [
    {
      to: '/dashboard',
      label: 'Dashboard',
      onClick: () => showDropdown.value = false,
    },
  ]

  if (isAdmin.value) {
    items.push({
      to: '/admin',
      label: 'Admin',
      onClick: () => showDropdown.value = false,
    })
  }

  if (isJudge.value || isAdmin.value) {
    items.push({
      to: '/judges',
      label: 'Judges',
      onClick: () => showDropdown.value = false,
    })
  }

  items.push({
    label: 'Logout',
    onClick: () => {
      logout()
      showDropdown.value = false
    },
  })

  return items
})

onMounted(() => {
  // me()
})
</script>

<template>
  <!-- Main toolbar -->
  <header class="flex px-5 lg:px-6 h-16 lg:h-25 relative">
    <div class="flex items-center gap-4 lg:gap-6">
      <!-- Logo -->
      <NuxtLink to="/" class="block transition-opacity hover:opacity-70 shrink-0">
        <IconsLogo class="block h-10 lg:h-15" />
      </NuxtLink>

      <!-- Place -->
      <div v-if="placeName" class="lg-max:hidden flex items-center font-accent uppercase text-2xl tracking-wider font-medium text-t-text">
        {{ placeName }}
      </div>

      <button v-if="placeName" type="button" :class="style.rightButton()" class="lg:hidden" @click="menuOpened = !menuOpened">
        <Icon :name="menuOpened ? 'ic:outline-close' : 'ic:outline-menu'" class="w-full h-full block" />
      </button>
    </div>

    <!-- Right -->
    <div class="flex ml-auto items-center gap-2 lg:gap-3">
      <!-- dark -->
      <DarkToggle :class="style.rightButton()" />

      <NuxtLink v-if="showCartButton" to="/cart" :active-class="style.rightButtonActive" :class="style.rightButton()">
        <CommonIndicator border class="theme-coral" />
        <Icon name="ic:outline-shopping-cart" class="w-full h-full block" />
      </NuxtLink>

      <!-- User menu -->
      <Dropdown v-if="user?.id" v-model="showDropdown" position="bottom-right" open-on-hover>
        <button type="button" :class="style.rightButton(showDropdown)">
          <Icon name="ic:outline-account-circle" class="w-full h-full block" />
        </button>

        <template #content>
          <div class="pt-1">
            <ul :class="style.dropdownMenu">
              <li v-for="item in menuItems" :key="item.label" :class="style.dropdownItem">
                <NuxtLink v-if="item.to" :to="item.to" :class="style.dropdownLink" :active-class="style.dropdownLinkActive" @click="item.onClick">
                  {{ item.label }}
                </NuxtLink>
                <button v-else :class="style.dropdownLink" @click="item.onClick">
                  {{ item.label }}
                </button>
              </li>
            </ul>
          </div>
        </template>
      </Dropdown>

      <!-- Not logged in -->
      <NuxtLink v-else to="/login" :class="style.loginButton()" :active-class="style.rightButtonActive">
        Login
      </NuxtLink>
    </div>

    <DashboardNavigation :opened="menuOpened" @close="menuOpened = false" />
  </header>
</template>
